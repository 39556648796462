<template>
	<div style="background: #fff;padding: 15px;" class="widthDrawal-box">
		<!-- tab切换 -->
		<el-tabs v-model="listState" @tab-click="filterFun">
			<el-tab-pane v-for="item in listStateList" :key="item.id" :label="item.value" :name="item.id + ''"></el-tab-pane>
		</el-tabs>
		<!-- //认证申请 -->
		<div v-if="listState == 0">
			<div class="filter-container">
				<div class="filter-item">
					<label class="label" style="white-space: nowrap;font-weight: 700;">关键字:</label>
					<el-input v-model="searchKey" placeholder="姓名、手机号" style="width: 300px;"></el-input>
				</div>
				<div class="filter-item">
					<label class="label" style="white-space: nowrap;">状态:</label>
					<el-select v-model="State" clearable style="margin-right: 10px;">
						<el-option label="全部" :value="null"></el-option>
						<el-option v-for="(item, index) in StateList" :key="index" :label="item.type" :value="item.id"></el-option>
					</el-select>
				</div>
				<div class="filter-item">
					<label class="label" style="margin-left: 40px;white-space: nowrap;">申请时间:</label>
					<el-date-picker v-model="startTime" type="datetime" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss"
					 :picker-options="maxOptions" placeholder="开始时间"></el-date-picker>
					<span style="padding: 0 10px;">~</span>
					<el-date-picker v-model="endTime" type="datetime" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss"
					 :picker-options="minOptions" placeholder="结束时间"></el-date-picker>
				</div>
				<div class="filter-item">
					<el-button type="primary" style="margin-left:30px;width: 90px;" size="small" @click="goodsSearch">查询</el-button>
					<el-button style="margin-left:20px;width: 90px;" size="small" @click="exportFun">导出</el-button>
				</div>
			</div>

			<div class="table-container" style="margin-top: 10px;">
				<el-table :data="groupData" style="width: 100%;" v-loading="loading" ref="multipleTable">
					<el-table-column label="客户" width="180" :key="1">
						<template slot-scope="scope">
							<div class="productInfo">
								<div class="Imgs">
									<img v-if="scope.row.WxHeadUrl" :src="scope.row.WxHeadUrl">
									<img v-else :src="defaultHeader">
								</div>
								<div class="Contains" style="margin-left: 8px;">
									<div class="top" style="display:flex">
										<div class="overFlow">
											{{scope.row.Name}}
										</div>
									</div>
									<div style="display: inline-block;margin-top: 8px;">{{scope.row.Phone}}</div>
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column label="地区" :key="2">
						<template slot-scope="scope">
							<span>{{scope.row.CertificateProvince + scope.row.CertificateCity}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="CertificateShopName" label="店铺" :key="3"></el-table-column>
					<el-table-column prop="BirthdayComplete" label="生日" :key="4">
						<template slot-scope="scope">
							<span>{{scope.row.BirthdayComplete}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="GenderValue" label="性别" :key="5">
						<template slot-scope="scope">
							<div>{{scope.row.GenderValue}}</div>
						</template>
					</el-table-column>
					<el-table-column prop="CertificateStateValue" label="状态" :key="6"></el-table-column>
					<el-table-column prop="CertificateApplyTime" label="申请时间" :key="7"></el-table-column>
					<el-table-column label="备注" :key="8" width="250px">
						<template slot-scope="scope">
							<div style="color: #F56C6C;">{{ scope.row.CertificateRemark}}</div>
						</template>
					</el-table-column>
					<el-table-column label="操作" width="250" :key="9">
						<template slot-scope="scope">
							<el-button type="text" @click="verifyOk(scope.row,1)" v-if="scope.row.CertificateState == 1 ">审核通过</el-button>
							<el-button type="text" style="color:#F56C6C;" @click="verifyRefused(scope.row)" v-if="scope.row.CertificateState == 1 ">审核否决</el-button>
							<el-button type="text" style="color:#F56C6C;" @click="verifyOk(scope.row,2)" v-if="scope.row.CertificateState == 2 ">取消认证</el-button>
							<el-button type="text" @click='checkdetails(scope.row)'>查看</el-button>
						</template>
					</el-table-column>
				</el-table>
				<div style="display:flex;justify-content:space-between;align-items:center;">
					<div style="display:flex;font-size: 12px;color: #606266;align-items:center;margin-left: 13px;"></div>
					<el-pagination v-if="Total" style="margin-top:20px;float:right;" @size-change="handleSizeChange" @current-change="handleCurrentChange"
					 :current-page="currentPage" :page-sizes="[10, 20, 30, 40, 50]" :page-size="20" layout="total, sizes, prev, pager, next, jumper"
					 :total="Total"></el-pagination>
				</div>
			</div>
		</div>
		<!-- //认证设置 -->
		<div v-if="listState == 1" v-loading='loading'>
			<el-form :model="ruleForm" ref="ruleForm" label-width="170px" :rules="rules">
				<el-form-item label="开启/关闭认证登录：">
					<el-switch v-model="ruleForm.IsOpenMemberCertificate">
					</el-switch>
				</el-form-item>
				<el-form-item label="未认证登录首页封面：" prop="MallLogoUrl">
					<el-upload class="avatar-uploader" :action="imgApi" :show-file-list="false" :on-success="handleAvatarSuccess"
					 list-type="picture-card" style="display: inline-block;marin-left:10px;">
						<img v-if="ruleForm.CertificatePageImgUrl" :src="imgUrl + ruleForm.CertificatePageImgUrl" class="upload-avatar"
						 style="width: 148px;height:148px" />
						<i v-else class="el-icon-plus"></i>
					</el-upload>
					<div style="color: #999;">建议上传图片的宽为750px，高度不限，小于等于2M</div>
				</el-form-item>
				<el-form-item label="客户认证信息：">
					<div style="display: flex;">
						<el-checkbox v-model="checked" disabled>姓名</el-checkbox>
						<el-checkbox v-model="checked" disabled>手机号</el-checkbox>
						<el-checkbox-group v-model="ruleForm.CertificateList" style="margin-left: 20px;">
							<el-checkbox :label="1">地区</el-checkbox>
							<el-checkbox :label="2">店铺</el-checkbox>
							<el-checkbox :label="3">生日</el-checkbox>
							<el-checkbox :label="4">性别</el-checkbox>
						</el-checkbox-group>
					</div>

					<div style="color: #999;">选择客户认证时需提交的信息，姓名和手机号为必填字段</div>
				</el-form-item>
			</el-form>
			<div class="bottom-save-btn">
				<el-button style="width:240px" type='primary' @click='saveCertificate'>保存</el-button>
			</div>
		</div>

		<!-- 提示弹窗 -->
		<el-dialog :title="PromptIndex == 1?'审核通过':'取消认证'" :visible.sync="verifyDialogShow" width="500px">
			<!-- //审核通过 -->
			<div style="line-height: 25px;" v-if="PromptIndex == 1">
				确认是否通过{{ refusedRow.Name }}的认证申请
			</div>
			<!-- //取消授权 -->
			<div style="line-height: 25px;" v-if='PromptIndex == 2'>
				<span>确认是否取消{{ refusedRow.Name }}的认证，取消后不可恢复</span>。
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="verifyDialogShow = false">关闭</el-button>
				<el-button style="margin-left: 30px;" type="primary" @click="verifySuccess()">确认</el-button>
			</span>
		</el-dialog>

		<!-- 审核否决提示弹窗 -->
		<el-dialog :visible.sync="refusedDialogShow" width="500px" center :show-close="false" title="审核否决">
			<el-form :model="ruleForm2" :rules="rules2" ref="ruleForm2" label-width="80px" class="demo-ruleForm">
				<el-form-item label="否决原因" prop="refusedReason">
					<el-input type="textarea" :rows="4" v-model="ruleForm2.refusedReason"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button style="width: 120px;" @click="authRefused('ruleForm2')">关闭</el-button>
				<el-button style="width: 120px;margin-left:30px;" type="primary" @click="authSuccess('ruleForm2')">确认否决</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		memberCertificateList,
		memberCertificateCheck,
		memberCertificateCancel,
		certificateSettingInfo,
		certificateSettingEdit
	} from '@/api/TurnTomySelf.js';
	import config from '@/config/index'
	export default {
		data() {
			return {
				goUrls: config.GO_URL,
				checked: true,
				exportUrl: config.EXPORT_URL,
				imgUrl: config.IMG_BASE,
				imgApi: config.UPLOAD_IMG,
				ruleForm: {
					IsOpenMemberCertificate: false,
					CertificatePageImgUrl: '',
					CertificateList: []
				},
				rules: {
					CertificatePageImgUrl: [{
						required: true,
						message: '未认证登录首页封面',
						trigger: 'change'
					}],
				},
				ruleForm2: {
					refusedReason: ''
				},
				rules2: {
					refusedReason: [{
							required: true,
							message: '请输入审核否决原因',
							trigger: 'blur'
						},
						{
							min: 1,
							max: 200,
							message: '否决原因最多可输入200字',
							trigger: 'blur'
						}
					]
				},
				refusedDialogShow: false, //审核否决弹窗
				defaultHeader: config.DEFAULT_HEADER,
				loading: false,
				listStateList: [{
						id: 0,
						value: '认证申请'
					},
					{
						id: 1,
						value: '认证设置'
					}
				],
				listState: '0',
				searchKey: '',
				startTime: '',
				endTime: '',
				State: null,
				StateList: [{
					type: '审核中',
					id: 1
				}, {
					type: '已审核',
					id: 2
				}, {
					type: '审核否决',
					id: 3
				}, {
					type: '取消认证',
					id: 4
				}],
				groupData: [],
				currentPage: 1,
				pageSize: 20,
				Total: 1,
				PromptTitle: '审核通过',
				verifyDialogShow: false,
				refusedRow: {},
				PromptIndex: 1
			}
		},
		methods: {
			checkdetails(record) {
				let headsUrls = process.env.NODE_ENV == 'production' ? 'index.html#' : ''
				let url = this.goUrls + headsUrls + '/Customer/CustomerDetail?Id=' + record.Id
				window.open(url)
			},
			async saveCertificate() {
				try {
					this.loading = true

					if (!this.ruleForm.CertificatePageImgUrl) {
						this.$message({
							showClose: true,
							type: 'error',
							message: '请上传未认证登录首页封面'
						});
						return
					}
					let data = {
						IsOpenMemberCertificate: this.ruleForm.IsOpenMemberCertificate,
						CertificatePageImgUrl: this.ruleForm.CertificatePageImgUrl,
						CertificateList: this.ruleForm.CertificateList
					}
					let result = await certificateSettingEdit(data)
					if (result.IsSuccess) {
						this.$message({
							showClose: true,
							type: 'success',
							message: '操作成功'
						});
						this.getDrawData()
					}
				} catch (err) {

				} finally {
					this.loading = false
				}
			},
			handleAvatarSuccess(res, file) {
				const isLt2M = file.size / 1024 / 1024 < 2;
				if (!isLt2M) {
					this.$message({
						showClose: true,
						type: 'error',
						message: '上传图片大小不能超过2M哦!'
					});
					return false;
				}
				this.ruleForm.CertificatePageImgUrl = res[0];
				this.$forceUpdate();
			},
			async authRefusedData() {
				try {
					this.loading = true
					let data = {
						MemberId: this.refusedRow.Id,
						IsCheckPass: false,
						FailReason: this.ruleForm2.refusedReason
					}
					let result = await memberCertificateCheck(data)
					if (result.IsSuccess) {
						this.$message({
							showClose: true,
							type: 'success',
							message: '操作成功'
						});
						this.refusedDialogShow = false
						this.getAuthData()
					}
				} catch (err) {

				} finally {
					this.loading = false
				}
			},
			authSuccess(formName) {
				this.$refs[formName].validate(async (valid) => {
					if (valid) {
						this.refusedDialogShow = false;
						this.authRefusedData();
					} else {
						return false;
					}
				});
			},
			authRefused(formName) {
				this.refusedDialogShow = false;
				this.$refs[formName].resetFields();
			},
			verifyRefused(row) {
				this.ruleForm2.refusedReason = ''
				this.isBatchRefused = false;
				this.refusedRow = row;
				this.ruleForm2.refusedReason = ''
				this.refusedDialogShow = true;
			},
			async getAuthData() {
				try {
					this.loading = true
					let data = {
						KeyWords: this.searchKey,
						CertificateState: this.State,
						StartTime: this.startTime,
						EndTime: this.endTime,
						Skip: (this.currentPage - 1) * this.pageSize, // 略过数据
						Take: this.pageSize, // 取的数据
					}
					let result = await memberCertificateList(data);
					this.Total = result.Result.Total;
					this.groupData = result.Result.Results || [];
				} catch (err) {

				} finally {
					this.loading = false
				}
			},
			async verifySuccess() {
				try {
					this.loading = true
					if (this.PromptIndex == 1) {
						let data = {
							MemberId: this.refusedRow.Id,
							IsCheckPass: true,
							FailReason: ''
						}
						let result = await memberCertificateCheck(data)
						if (result.IsSuccess) {
							this.$message({
								showClose: true,
								type: 'success',
								message: '操作成功'
							});
							this.verifyDialogShow = false
							this.getAuthData()
						}
					}
					//取消认证
					else {
						let data = {
							MemberId: this.refusedRow.Id
						}
						let result = await memberCertificateCancel(data)
						if (result.IsSuccess) {
							this.$message({
								showClose: true,
								type: 'success',
								message: '操作成功'
							});
							this.verifyDialogShow = false
							this.getAuthData()
						}
					}
				} catch (err) {

				} finally {
					this.loading = false
				}
			},
			//审核通过
			verifyOk(record, index) {
				this.refusedRow = record
				this.PromptIndex = index
				this.verifyDialogShow = true
			},

			goodsSearch() {
				this.currentPage = 1
				this.getAuthData();
			},
			//顶部tab切换事件
			filterFun(tab) {
				if (tab.index == 0) {
					this.getAuthData();
				} else if (tab.index == 1) {
					this.getDrawData();
				}
			},
			async getDrawData() {
				try {
					this.loading = true
					let result = await certificateSettingInfo()
					this.ruleForm = result.Result
				} catch (err) {

				} finally {
					this.loading = false
				}
			},
			async exportFun() {
				try {
					let url = this.exportUrl + '/pc/member/memberCertificateListExport?' +
						'&KeyWords=' + this.searchKey +
						'&CertificateState=' + (this.State ? this.State : '') +
						'&StartTime=' + (this.startTime ? this.startTime : '') +
						'&EndTime=' + (this.endTime ? this.endTime : '')
					window.open(url);
				} catch (error) {

				} finally {

				}

			},
			// 切换显示条数
			handleSizeChange(val) {
				this.pageSize = val;
				this.getAuthData();
			},

			// 翻页
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getAuthData();
			},
		},
		created() {
			this.getAuthData();
		},
		computed: {
			//日期选择器限制选择
			minOptions: function() {
				let limitTime = this.startTime;
				return {
					disabledDate(time) {
						if (limitTime) {
							return time < new Date(limitTime);
						}
					}
				};
			},
			//日期选择器限制选择
			maxOptions: function() {
				let limitTime = this.endTime;
				return {
					disabledDate(time) {
						return (
							time > new Date(limitTime)
							// ||time < Date.now()
						);
					}
				};
			}
		}
	}
</script>

<style lang="less" scoped>
	.bottom-save-btn {
		position: fixed;
		width: 100%;
		background: #fff;
		bottom: 0;
		text-align: center;
		padding: 20px 0;
		z-index: 100;
		box-shadow: 13px 1px 6px #999;
	}

	.productInfo {
		display: flex;

		.Activestyle {
			font-size: 12px;
			background: #F56C6C;
			color: #fff;
			text-align: center;
			padding-left: 4px;
			padding-right: 4px;
			box-sizing: border-box;

		}
	}

	.productInfo .Imgs img {
		width: 50px;
		height: 50px;
		border-radius: 50%;
		border: none;
		object-fit: initial;
	}

	.productInfo .overFlow {
		width: 100px;
		margin-right: 5px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
</style>
